import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import { useModel } from '@@/exports';
import { useTranslation } from 'react-i18next';
import style from '../SearchStyle.less';
import { SearchStateType } from '../useStateHooks';

/**
 * 搜索页面顶部
 */
export default ({
  search,
  setSearch,
}: Pick<SearchStateType, 'search' | 'setSearch'>) => {
  const { t } = useTranslation();
  const { isMobile } = useModel('system');
  return (
    <div className="pt-4 flex items-center">
      <div className="text-center flex-1">
        <BaseInput
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={!isMobile ? t('请输入搜索关键字') : ''}
          className={style['input-animation-widen']}
          classNames={{
            inputWrapper: 'rounded-[24px]',
          }}
          startContent={
            <BasilSearchSolid className=" text-iconFontColor text-xl " />
          }
        />
      </div>
      {isMobile && (
        <div
          onClick={() => {
            history.back();
          }}
          className="p-2 h-[100%] flex items-center whitespace-nowrap cursor-pointer"
        >
          {t('取消')}
        </div>
      )}
    </div>
  );
};
