import BaseTabs from '@/components/base/baseTabs';
import {
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';

import {  useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarKetSearch from './components/MarKetSearch';
import { parseQueryString } from '@/utils';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import CollectList from '@/pages/public/market/components/CollectList';
import CoinList from '@/pages/public/market/components/CoinList';
import ContractList from '@/pages/public/market/components/ContractList';
import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { Divider } from '@nextui-org/react';

interface IProps {
  /**
   * 是否是大宗交易
   */
  isTrad?: boolean;
}

/**
 * 搜索页面
 */
export default () => {
  const { t } = useTranslation();
  //币种列表配置
  const tabOptions = [
    { text: t('合约'), value: GetCoinListTypeEnum.CONTRACT },
    { text: t('现货'), value: GetCoinListTypeEnum.COIN },
    { text: t('自选'), value: GetCoinListTypeEnum.COLLECT },
  ];
  const urlState = useMemo(() => {
    return parseQueryString(window.location.search);
  }, [window.location.search]);
  //默认选中 的
  const [tabSelected, setTabSelected] = useState(
    urlState?.type ?? GetCoinListTypeEnum.CONTRACT,
  );
  const [filterText,setFilterText] = useState('')

  // 加载
  const [loadingDisplay,setLoadingDisplay] = useState(false)
  return (
    <div className=" flex-1">
      {/* 搜索框 */}
      <div className='px-4'>
              <MarKetSearch
        search={filterText}
        setSearch={setFilterText}
      ></MarKetSearch>
      </div>
      {/* tab */}
      <div className=' w-full flex flex-wrap items-center justify-between'>
      <div className='px-3 px-[10px] flex justify-between items-center w-full '>
              <BaseTabs
        value={tabSelected}
        onChange={setTabSelected}
        options={tabOptions}
        tabsPropsProps={{
          classNames: {
            tab: '!text-[16px] !px-1 !h-[40px]',
            tabList: '!pb-0',
          },
        }}
      />
      {/* 加载 */}
      {
        loadingDisplay && (
          <div className="flex items-center justify-center w-fit h-full">
            <SvgSpinnersBarsRotateFade />
          </div>
        )
      }
      </div>
      <Divider className="!bg-backgroundAuxiliaryColor/80" />

      </div>
      <div className='px-4'>
      {/*收藏展示*/}
      {
        GetCoinListTypeEnum.COLLECT === tabSelected && <CollectList fuzzy={filterText} setLoadingDisplay={setLoadingDisplay}/>
      }

      {/*币币*/}
      {
        GetCoinListTypeEnum.COIN === tabSelected && <CoinList fuzzy={filterText} setLoadingDisplay={setLoadingDisplay}/>
      }

      {/*合约*/}
      {
        GetCoinListTypeEnum.CONTRACT === tabSelected && <ContractList fuzzy={filterText} setLoadingDisplay={setLoadingDisplay}/>
      }
      </div>
    </div>
  );
};
